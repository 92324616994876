import React, { useRef, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { userStatus, setTaskStateApiCbk, apiURL } from "./WmUtils";
import { showBackendError } from "./ShowError";
import { backendErrorMessage } from "../shared/backendError";
import { toast } from "react-toastify";
import "../index.css";
import { OMEFileButton } from "./OMEFileButton";
import useInvoiceStatusStore from "../stores/useInvoiceStatusStore.jsx";
import useTaskDetailsFormControlStore, { SAVE_CONTROL_START, SAVE_CONTROL_SUCCESS, SAVE_CONTROL_FAILED } from "../stores/TaskDetailsFormControlStore";
import { ButtonLoader } from "./ButtonLoader.jsx"


export const ControlButtons = ({
  actTask,
  actTaskState,
  comStat,
  onSaveWorkSheet,
  SetActTaskState,
  showOMEFileButton,
  taskList,
  taskStateList,
  disallowOMELoad,
  waterMeterList,
  setOverAllAcrdActiveKey

}) => {
  const { loading, setLoading, loadingTarget, setLoadingTarget } = useTaskDetailsFormControlStore((state) => ({
    loading: state.loading,
    setLoading: state.setLoading,
    loadingTarget: state.loadingTarget,
    setLoadingTarget: state.setLoadingTarget,
  }));
  const propStateTaskListActTask = useRef(taskList.find((x) => x.task_id === actTask));
  const taskURL = apiURL + "/tasks";

  const { invoiceStatus } = useInvoiceStatusStore((state) => ({ invoiceStatus: state.invoiceStatus }));

  useEffect(() => {
    propStateTaskListActTask.current = taskList.find((x) => x.task_id === actTask);
  }, [taskList, actTask]);

  const saveAndStart = async () => {
    if (actTaskState === taskStateList.CREATED || actTaskState === taskStateList.STARTED || actTaskState === taskStateList.CANCELED || actTaskState === taskStateList.REORGANIZED) {
      try {
        onSaveWorkSheet(SAVE_CONTROL_START, async () => {
          await startOrBackCbk();
        });
      } catch (error) {
        showBackendError(error);
      }
    } else {
      backWithLoader();
    }
  };

  const backWithLoader = async () => {
    setLoading(true);
    setLoadingTarget(SAVE_CONTROL_START);
    try {
      await startOrBackCbk();
    } finally {
      setLoading(false);
      setLoadingTarget("");
    }
  };

  const startOrBackCbk = async () => {
    if (comStat === userStatus.offline) {
      toast.warning(
        <div>
          Az alkalmazás offline állapontban van,
          <br /> nem lehetséges a státuszváltoztatás
        </div>
      );
      return;
    }
    let apiTransStr = "";
    if (actTaskState === taskStateList.STARTED) {
      apiTransStr = "undo-start";
    } else {
      if (actTaskState === taskStateList.FINISHED) {
        apiTransStr = "undo-finish";
      } else if (actTaskState === taskStateList.FAILED) {
        apiTransStr = "undo-fail";
      } else {
        apiTransStr = "start";
      }
    }

    const retVal = await setTaskStateApiCbk(`${taskURL}/${propStateTaskListActTask.current.task_id}/${apiTransStr}/`);

    if (retVal?.status === 200 && retVal.data && retVal.data.status) {
      SetActTaskState(taskStateList[retVal.data.status]);
      setOverAllAcrdActiveKey("1");
    } else {
      showBackendError(backendErrorMessage("Hiba a feladat státuszváltása közben. ", retVal));
    }
  };

  const saveAndFinish = async () => {
    try {
      await onSaveWorkSheet(SAVE_CONTROL_SUCCESS, async () => {
        await finishCbk();
      });
    } catch (error) {
      showBackendError(error);
    }
  };

  const saveAndFailCallBack = async () => {
    try {
      await onSaveWorkSheet(SAVE_CONTROL_FAILED, async () => {
        await failCbk();
      });
    } catch (error) {
      showBackendError(error);
    }
  };

  const finishCbk = async () => {
    if (comStat === userStatus.offline) {
      toast.warning(
        <div>
          Az alkalmazás offline állapontban van,
          <br /> nem lehetséges a státuszváltoztatás
        </div>
      );
      return;
    }
    const retVal = await setTaskStateApiCbk(`${taskURL}/${propStateTaskListActTask.current.task_id}/finish/`);

    if (retVal?.status === 200 && retVal.data && retVal.data.status) {
      SetActTaskState(taskStateList[retVal.data.status]);
    } else {
      showBackendError(backendErrorMessage("Hiba a feladat státuszváltása közben. ", retVal));
    }
  };

  const failCbk = async () => {
    if (comStat === userStatus.offline) {
      toast.warning(
        <div>
          Az alkalmazás offline állapontban van,
          <br /> nem lehetséges a státuszváltoztatás
        </div>
      );
      return;
    }
    const retVal = await setTaskStateApiCbk(`${taskURL}/${propStateTaskListActTask.current.task_id}/fail/`);

    if (retVal?.status === 200 && retVal.data && retVal.data.status) {
      SetActTaskState(taskStateList[retVal.data.status]);
    } else {
      showBackendError(backendErrorMessage("Hiba a feladat státuszváltása közben. ", retVal));
    }
  };

  return (
    <>
      <div className="backOrBeginButton">
        <div className="d-flex justify-content-start">
          {actTaskState === taskStateList.SCHEDULED ? (
            <Button className="px-2 my-2 mx-2 p-2" onClick={saveAndStart} disabled={loading}>
              <div>
                Elkezdtem
                {loading && loadingTarget === SAVE_CONTROL_START && (
                  <ButtonLoader />)}
              </div>
            </Button>
          ) : (
            <Button className="px-2 my-2 mx-2 p-2" onClick={backWithLoader} disabled={invoiceStatus === 2 || loading}>
              <div>
                Vissza
                {loading && loadingTarget === SAVE_CONTROL_START && (
                  <ButtonLoader />)}
              </div>
            </Button>
          )}
          {actTaskState === taskStateList.STARTED && (
            <Button className="px-2 my-2 mx-2 p-2" onClick={saveAndFinish} disabled={loading}>
              <div>
                Sikerült
                {loading && loadingTarget === SAVE_CONTROL_SUCCESS && (
                  <ButtonLoader />)}
              </div>
            </Button>
          )}

          {actTaskState === taskStateList.STARTED && (
            <Button className="px-2 my-2 mx-0 p-2" onClick={saveAndFailCallBack} disabled={loading}>
              <div>
                Meghiúsult
                {loading && loadingTarget === SAVE_CONTROL_FAILED && (
                  <ButtonLoader />)}
              </div>
            </Button>
          )}
        </div>
      </div>
      {showOMEFileButton && (
        <div className="d-flex justify-content-end">
          <OMEFileButton actTask={actTask} disallowOMELoad={disallowOMELoad} waterMeterList={waterMeterList} />
        </div>
      )}
    </>
  );
};
